var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "search-page" } }, [
    _c(
      "div",
      { staticClass: "search-page__search-bar flex items-center" },
      [
        _c("vs-input", {
          staticClass: "w-full input-rounded-full",
          attrs: {
            "icon-no-border": "",
            placeholder: "Search",
            icon: "icon-search",
            "icon-pack": "feather",
          },
          model: {
            value: _vm.searchQuery,
            callback: function ($$v) {
              _vm.searchQuery = $$v
            },
            expression: "searchQuery",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass:
          "search-page__serch-menu flex flex-wrap items-center md:justify-between mt-8",
      },
      [
        _c(
          "div",
          { staticClass: "flex flex-wrap" },
          [
            _c("span", { staticClass: "search-tab-filter shadow-drop" }, [
              _vm._v("All"),
            ]),
            _c("span", { staticClass: "search-tab-filter shadow-drop" }, [
              _vm._v("Images"),
            ]),
            _c("span", { staticClass: "search-tab-filter shadow-drop" }, [
              _vm._v("Video"),
            ]),
            _c("span", { staticClass: "search-tab-filter shadow-drop" }, [
              _vm._v("Maps"),
            ]),
            _c("span", { staticClass: "search-tab-filter shadow-drop" }, [
              _vm._v("News"),
            ]),
            _c(
              "vs-dropdown",
              {
                staticClass: "search-tab-filter shadow-drop",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c("span", [_vm._v("More")]),
                _c(
                  "vs-dropdown-menu",
                  { staticClass: "search-page__more-dropdown" },
                  [
                    _c("vs-dropdown-item", [_vm._v("Shopping")]),
                    _c("vs-dropdown-item", [_vm._v("Books")]),
                    _c("vs-dropdown-item", [_vm._v("Flight")]),
                    _c("vs-dropdown-item", [_vm._v("Finance")]),
                    _c("vs-dropdown-item", [_vm._v("Personal")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "vs-dropdown",
              {
                staticClass: "search-tab-filter shadow-drop",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c("span", [_vm._v("Settings")]),
                _c(
                  "vs-dropdown-menu",
                  { staticClass: "search-page__settings-dropdown w-64" },
                  [
                    _c("vs-dropdown-item", [_vm._v("Search settings")]),
                    _c("vs-dropdown-item", [_vm._v("Language")]),
                    _c("vs-dropdown-item", [_vm._v("Turn on SafeSearch")]),
                    _c("vs-dropdown-item", [_vm._v("Hide Private Results")]),
                    _c("vs-dropdown-item", [_vm._v("Advanced Search")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("span", { staticClass: "search-tab-filter mr-0 shadow-drop" }, [
              _vm._v("Tools"),
            ]),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "search-meta flex flex-wrap justify-between mt-6" },
      [
        _c("span", { staticClass: "mb-4" }, [
          _vm._v("Approx 84,00,00,000 results (0.35s)"),
        ]),
        _c(
          "div",
          [
            _c(
              "vs-dropdown",
              {
                staticClass: "cursor-pointer",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c(
                  "span",
                  { staticClass: "flex items-center" },
                  [
                    _c("span", [_vm._v("Any Time")]),
                    _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      staticStyle: { width: "1rem", height: "1rem" },
                      attrs: { icon: "ChevronDownIcon" },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-dropdown-menu",
                  { staticClass: "w-48" },
                  [
                    _c("vs-dropdown-item", [_vm._v("Any Time")]),
                    _c("vs-dropdown-item", [_vm._v("Past Hour")]),
                    _c("vs-dropdown-item", [_vm._v("Past 24 Hours")]),
                    _c("vs-dropdown-item", [_vm._v("Past Week")]),
                    _c("vs-dropdown-item", [_vm._v("Past Month")]),
                    _c("vs-dropdown-item", [_vm._v("Past Year")]),
                    _c("vs-dropdown-item", [_vm._v("Custom Period")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v("\n             \n            "),
            _c(
              "vs-dropdown",
              {
                staticClass: "cursor-pointer",
                attrs: { "vs-trigger-click": "" },
              },
              [
                _c(
                  "span",
                  { staticClass: "flex items-center" },
                  [
                    _c("span", [_vm._v("All Results")]),
                    _c("feather-icon", {
                      staticClass: "cursor-pointer",
                      staticStyle: { width: "1rem", height: "1rem" },
                      attrs: { icon: "ChevronDownIcon" },
                    }),
                  ],
                  1
                ),
                _c(
                  "vs-dropdown-menu",
                  { staticClass: "w-32" },
                  [
                    _c("vs-dropdown-item", [_vm._v("All Result")]),
                    _c("vs-dropdown-item", [_vm._v("Verbatim")]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "vx-row mt-4 md:flex-row flex-col-reverse" }, [
      _c(
        "div",
        { staticClass: "vx-col md:w-3/5 lg:w-2/3 w-full" },
        [
          _c(
            "vx-card",
            { staticClass: "search-page__search-results lg:p-2" },
            _vm._l(_vm.searchResults, function (result, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "vx-row search-Page__search-result",
                  class: { "mt-8": index },
                },
                [
                  result.resultImg || result.resultVideo
                    ? _c(
                        "div",
                        {
                          staticClass: "vx-col mb-2",
                          class:
                            result.resultImg || result.resultVideo
                              ? "lg:w-1/5 md:w-1/4 w-full"
                              : "w-full",
                        },
                        [
                          result.resultImg
                            ? _c("img", {
                                staticClass: "responsive",
                                attrs: {
                                  src: result.resultImg,
                                  alt: "result-img",
                                },
                              })
                            : result.resultVideo
                            ? _c("video-player", {
                                ref: "player",
                                refInFor: true,
                                staticClass: "media-video-player",
                                attrs: {
                                  options: _vm.playerOptions(
                                    result.resultVideo[0]
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "vx-col",
                      class:
                        result.resultImg || result.resultVideo
                          ? "lg:w-4/5 md:w-3/4"
                          : "w-full",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "inline-block text-2xl",
                          attrs: {
                            href: result.linkUrl,
                            target: "_blank",
                            rel: "nofollow",
                          },
                        },
                        [_vm._v(_vm._s(result.title))]
                      ),
                      _c("br"),
                      _c(
                        "a",
                        {
                          staticClass: "inline-block text-success mb-1",
                          attrs: {
                            href: result.resultUrl,
                            target: "_blank",
                            rel: "nofollow",
                          },
                        },
                        [_vm._v(_vm._s(result.resultUrl))]
                      ),
                      _c("br"),
                      result.metaData
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-wrap items-center search-page__search-result-meta my-1",
                            },
                            [
                              result.metaData.ratings
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex items-center search-page__search-result-ratings mr-3",
                                    },
                                    [
                                      _vm._l(
                                        Math.floor(result.metaData.ratings),
                                        function (i) {
                                          return _c("img", {
                                            key: i,
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: require("@/assets/images/raty/star-on-2.png"),
                                              alt: "rating",
                                            },
                                          })
                                        }
                                      ),
                                      result.metaData.ratings % 1
                                        ? _c("img", {
                                            staticClass: "mb-1",
                                            attrs: {
                                              src: require("@/assets/images/raty/star-half-2.png"),
                                              alt: "rating",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                _vm._l(
                                  result.metaData.info,
                                  function (info, infoIndex) {
                                    return _c(
                                      "span",
                                      { key: infoIndex, staticClass: "mr-2" },
                                      [
                                        _vm._v(_vm._s(info) + " "),
                                        infoIndex <
                                        result.metaData.info.length - 1
                                          ? _c("span", [_vm._v("|")])
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          )
                        : _vm._e(),
                      result.time
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("date")(result.time, true)) + " - "
                            ),
                          ])
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("tailing")(
                              _vm._f("truncate")(result.description, 225),
                              "..."
                            )
                          )
                        ),
                      ]),
                      result.sitelinks
                        ? _c(
                            "div",
                            { staticClass: "vx-row mt-6" },
                            _vm._l(
                              result.sitelinks,
                              function (sitelink, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "vx-col w-full sm:w-1/2 lg:w-1/3 mb-5",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "inline-block mb-1",
                                        attrs: {
                                          href: sitelink.url,
                                          target: "_blank",
                                          rel: "nofollow",
                                        },
                                      },
                                      [_vm._v(_vm._s(sitelink.title))]
                                    ),
                                    _c("br"),
                                    _c("p", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("tailing")(
                                            _vm._f("truncate")(
                                              sitelink.description,
                                              50
                                            ),
                                            "..."
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
          _c("vs-pagination", {
            staticClass: "mt-base",
            attrs: { total: 40 },
            model: {
              value: _vm.currentPage,
              callback: function ($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col md:w-2/5 lg:w-1/3 w-full mb-base" },
        [
          _c(
            "vx-card",
            {
              staticClass: "bg-transparent no-shadow",
              attrs: { "card-border": "" },
            },
            [
              _c(
                "div",
                { staticClass: "search-page__search-img-gallery vx-row mb-4" },
                [
                  _c("div", { staticClass: "vx-col w-full" }, [
                    _c("img", {
                      staticClass: "responsive shadow-md rounded-lg",
                      attrs: {
                        src: _vm.knowledgePanel.img,
                        alt: "gallery-img",
                      },
                    }),
                  ]),
                ]
              ),
              _c("h3", [_vm._v(_vm._s(_vm.knowledgePanel.title))]),
              _c("small", [_vm._v(_vm._s(_vm.knowledgePanel.subtitle))]),
              _c(
                "div",
                { staticClass: "knowledgePanel__external-link flex my-2" },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: _vm.knowledgePanel.externalLink.icon,
                      svgClasses: "w-4 h-4 mb-1 mr-2",
                    },
                  }),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.knowledgePanel.externalLink.url,
                        target: "_blank",
                        rel: "nofollow",
                      },
                    },
                    [_vm._v(_vm._s(_vm.knowledgePanel.externalLink.title))]
                  ),
                ],
                1
              ),
              _c("p", [_vm._v(_vm._s(_vm.knowledgePanel.description))]),
              _c(
                "div",
                { staticClass: "knowledge-panel__info-list flex mt-6" },
                _vm._l(_vm.knowledgePanel.info, function (info, index) {
                  return _c(
                    "div",
                    {
                      key: info.title,
                      staticClass:
                        "knowledge-panel__info flex-1 text-center border-solid border-grey-light border border-r-0 border-b-0 border-t-0",
                      class: { "border-l-0": index == 0 },
                    },
                    [
                      _c("p", { staticClass: "font-medium" }, [
                        _vm._v(_vm._s(info.title)),
                      ]),
                      _c("small", [_vm._v(_vm._s(info.subtitle))]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "knowledge-panel__meta-list mt-6" },
                _vm._l(_vm.knowledgePanel.resultMetaList, function (meta) {
                  return _c(
                    "div",
                    { key: meta.name, staticClass: "knowledge-panel__meta" },
                    [
                      _c("p", [
                        _c("span", { staticClass: "font-medium mr-2" }, [
                          _vm._v(_vm._s(meta.name) + ":"),
                        ]),
                        _vm._v(" " + _vm._s(meta.value)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "knowledge-panel-suggestions mt-6" }, [
                _c("p", { staticClass: "text-lg font-medium mb-2" }, [
                  _vm._v("People also search for"),
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "knowledge-panel__suggested-list flex flex-wrap",
                  },
                  _vm._l(
                    _vm.knowledgePanel.suggestedSearches,
                    function (search) {
                      return _c(
                        "div",
                        {
                          key: search.name,
                          staticClass:
                            "knowledge-panel__suggestion mr-4 text-center",
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: search.url,
                                target: "_blank",
                                rel: "nofollow",
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "mx-auto",
                                attrs: {
                                  src: search.img,
                                  alt: "suggested-search-img",
                                  height: "40px",
                                  width: "40px",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-sm",
                              attrs: {
                                href: search.url,
                                target: "_blank",
                                rel: "nofollow",
                              },
                            },
                            [_vm._v(_vm._s(search.name))]
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }